import React, { useEffect, useState } from 'react'
import areaCast from '../../assets/area_cast.png'
import areaCat from '../../assets/area_cat.png'
import fedefarmaLogo from '../../assets/fedefarma-logo.png'
import Cookies from 'universal-cookie'
import { useNavigate, useSearchParams } from 'react-router-dom'
import LoginForm from '../LoginForm'
import ResetPasswordForm from '../ResetPasswordForm'

const LoginScreen = () => {
  const cookies = new Cookies()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false)
  // const [tryingToLogin, setTryingToLogin] = useState(true)
  const [userCode, setUserCode] = useState('')

  /* Checking if the user is logged in or not. If not, it redirects to the login page. */
  /* A workaround to avoid the infinite loop of redirects that happens when the user is not logged in. */
  useEffect(() => {
    const logout = searchParams.get('logout')
    if (logout) {
      cookies.set('avoid_sso_3', true, { path: '/', maxAge: 10 })
      navigate('/')
    }

    if (cookies.get('avoid_sso_3')) {
      // setTryingToLogin(false)
    } else {
      // if (cookies.get('avoid_sso_4') && !cookies.get('avoid_sso_5')) {
      //   cookies.set('avoid_sso_5', true, { path: '/', maxAge: 10 })
      // }
      // if (cookies.get('avoid_sso_3') && !cookies.get('avoid_sso_4')) {
      //   cookies.set('avoid_sso_4', true, { path: '/', maxAge: 10 })
      // }
      if (cookies.get('avoid_sso_2') && !cookies.get('avoid_sso_3')) {
        cookies.set('avoid_sso_3', true, { path: '/', maxAge: 10 })
      }

      if (cookies.get('avoid_sso_1') && !cookies.get('avoid_sso_2')) {
        cookies.set('avoid_sso_2', true, { path: '/', maxAge: 10 })
      }
      if (!cookies.get('avoid_sso_1')) {
        cookies.set('avoid_sso_1', true, { path: '/', maxAge: 10 })
      }
      location.href = window.location.href
      // document.location.href = 'https://accounts-dev.farmacloud.io/sso/areaprivada-prod?login=referrer' // farmacloud DEV
      window.location.href = 'https://accounts.farmacloud.io/sso/areaprivada-prod?login=referrer' // farmacloud PROD
    }
  }, [])

  const [lang] = useState(navigator.language || Navigator.userLanguage)

  // if (tryingToLogin) {
  //   return <div><header className='App-header' /></div>
  // }
  return (
    <div className='login'>
      {showResetPasswordForm && <div></div>}
      <header className='App-header'>
        <div
          className={
            'tw-flex tw-w-full tw-h-full tw-px-24 2xl:tw-px-52 tw-justify-center xl:tw-justify-between '
          }
        >
          <div
            className={
              'tw-pt-10 tw-flex tw-h-fullt tw-my-auto tw-flex-col tw-justify-center 2xl:tw-justify-center tw-hidden xl:tw-block tw-items-center'
            }
          >
            <img
              alt='Descubre el nuevo universo cloud'
              src={lang === 'es' ? areaCast : areaCat}
              width={330}
              height={350}
            />
          </div>
          <div
            className={
              'tw-bg-blue-300 tw-self-center tw-flex tw-h-[490px] tw-mr-0'
            }
          >
            <div
              className={
                'tw-flex tw-flex-col tw-w-[400px] tw-h-full tw-border-gray-400 tw-text-black tw-border-2 tw-rounded tw-bg-white tw-p-10'
              }
            >
              <img
                className={'tw-px-2 tw-py-6 tw-self-center'}
                alt='Fedefarma área privada'
                src={fedefarmaLogo}
                width={490}
              />
              {
                !showResetPasswordForm
                  ? <LoginForm userCode={userCode} setUserCode={setUserCode}
                             setShowResetPasswordForm={setShowResetPasswordForm} lang={lang}/>
                  : <ResetPasswordForm userCode={userCode} setUserCode={setUserCode} setShowResetPasswordForm={setShowResetPasswordForm}/>
              }
            </div>

          </div>
        </div>
      </header>
    </div>
  )
}

export default LoginScreen

// Legacy Farmacloud login:
// useEffect(() => {
//   const handler = (event) => {
//     if (event.origin === 'https://accounts.farmacloud.io') {
//       // console.log(event.data)
//       // if (event.data.token) {
//       //   window.location.href = `https://accounts.farmacloud.io/sso/CLIENT_ID?login=farmacloud`
//       // }
//       return window.removeEventListener('message', handler)
//     }
//   }
//   const iframe = document.createElement('iframe')
//   iframe.setAttribute(
//     'src',
//     'https://accounts.farmacloud.io/iframe/index.html'
//   )
//   iframe.style.display = 'none'
//   document.body.appendChild(iframe)
//   window.addEventListener('message', handler)
//   //
// }, [])
