import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
import { Error } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
// import axios from 'axios'

const ResetPasswordForm = ({ userCode, setUserCode, lang, setShowResetPasswordForm }) => {
  const {
    register,
    handleSubmit,
    // setError,
    formState: { errors }
  } = useForm()
  const [isSending, setIsSending] = useState(false)
  const [newPasswordCreated, setNewPasswordCreated] = useState(false)
  const onSubmit = async (data) => {
    setIsSending(true)
    return new Promise(() => setTimeout(() => {
      // console.log({ data })
      setIsSending(false)
      setNewPasswordCreated(true)
}, '2000'))
    // const callData = { ...data, origin: 'areaprivada' }
    // setLoading(true)
    // axios
    //   .post('https://back.sferafarma.cloud/api/v1/login-app', callData) // OUR PROD BACKEND
    //   // .post('https://back-sferafarma.enpreproduccion.com/api/v1/login-app', callData) // OUR DEV BACKEND
    //   .then((response) => {
    //     if (response?.status === 200 && response?.data?.url) {
    //       window.location.href = response.data.url
    //     }
    //   })
    //   .catch(() => {
    //     setLoading(false)
    //     setError('password', { type: 'string', message: 'custom message' })
    //   })
  }

  if (newPasswordCreated) {
    return (
      <div className={'tw-flex tw-flex-col tw-px-2 tw-pt-12 tw-relative'}>
        <Paragraphs
          size={'2xl'}
          className={'tw-text-[#666666] tw-self-start tw-ml-0 '}
        >
          {lang === 'es' ? 'Petición enviada correctamente' : 'Petició enviada correctament'}
        </Paragraphs>
        <Paragraphs
          size={'base'}
          className={'tw-text-[#666666] tw-self-start tw-ml-0 tw-pt-8 '}
        >
          {lang === 'es' ? 'En breve recibirás tu nueva contraseña en tu correo electrónico.' : 'En breu rebràs la teva nova contrasenya al correu electrònic.'}
        </Paragraphs>
        <div
          disabled={isSending}
          className={
            `tw-absolute tw-top-[270px] tw-right-0 tw-cursor-pointer tw-shadow-gray-black tw-rounded-full tw-text-xs tw-shadow tw-flex tw-justify-between tw-inline-block tw-px-4 tw-py-1 tw-bg-[#1867C0] tw-text-white
            ${isSending ? 'tw-opacity-50' : ' hover:tw-opacity-[95%] active:tw-opacity-70 '}`

          }
          onClick={() => setShowResetPasswordForm(false)}
        >
          <Paragraphs
            className={'tw-tracking-widest tw-scale-x-[0.90]'}
          >
            {lang === 'es' ? 'Volver' : 'Tornar'}
          </Paragraphs>
        </div>
      </div>
    )
  }

  return (
    <form
      name={'login-form'}
      onSubmit={handleSubmit(onSubmit)}
      className={
        'tw-h-48 tw-flex tw-flex-col tw-px-2 tw-pt-12 tw-relative'
      }
    >
      <Paragraphs
        size={'2xl'}
        className={'tw-text-[#666666] tw-self-start tw-ml-0 '}
      >
        {lang === 'es' ? 'Creación de nueva contraseña' : 'Creació de nova contrasenya'}
      </Paragraphs>
      <div className={'tw-pt-5'}>
        <label
          className={`tw-text-xs tw-block tw-text-left ${
            errors?.username
              ? 'tw-text-red-500'
              : 'tw-text-[#666666]}'
          }`}
        >
          <Paragraphs size={'xs'} className='tw-text-gray-900/75'>
            {lang === 'es'
              ? 'Código de usuario o dirección de correo'
              : "Codi d'usuari o adreça de mail"}
          </Paragraphs>
        </label>
        <input
          disabled={isSending}
          style={{ height: '25px', fontSize: '16px' }}
          className={
            'tw-bg-[#FFF] tw-block tw-text-lg tw-w-full tw-outline-0 tw-border-b tw-border-gray-600 focus:tw-border-[#AE458F] focus:tw-border-b-[2px] tw-transition-all tw-ease-in-out-300 tw-duration-300'
          }
          {...register('username', { required: true })}
          onChange={(e) => { setUserCode(e.target.value) }}
          value={userCode}
        />
        {errors.username ? (
          <Paragraphs
            size={'xs'}
            className={
              'tw-text-red-500 tw-text-left tw-leading-tight'
            }
          >
            {lang === 'es'
              ? 'Introduce el código de usuario'
              : "Entra el codi d'usuari"}
          </Paragraphs>
        ) : (
          <Paragraphs
            size={'xs'}
            className={'tw-text-white tw-text-left tw-leading-tight'}
          >
            .
          </Paragraphs>
        )}
      </div>
      <div className={'tw-mt-1'}>
        {errors.password && (
          <Paragraphs
            size={'xs'}
            className={
              'tw-text-red-500 tw-text-left tw-leading-tight '
            }
          >
            <Error className={'tw-scale-75 '} />{' '}
            {lang === 'es'
              ? 'El nombre de usuario no es válido'
              : "El nom d'usuari no és vàlid"}
          </Paragraphs>
        )}
      </div>
      {!isSending && <div className={'tw-absolute tw-top-[230px] tw-right-2'} onClick={() => setShowResetPasswordForm(false)}>
        <Paragraphs className={'tw-cursor-pointer tw-text-[#1867C0]'} size={'sm'}>
          {lang === 'es' ? 'Volver' : 'Tornar'}
        </Paragraphs>
      </div>}
      <div
        disabled={isSending}
        className={
          `tw-absolute tw-top-[270px] tw-right-0 tw-cursor-pointer tw-shadow-gray-black tw-rounded-full tw-text-xs tw-shadow tw-flex tw-justify-between tw-inline-block tw-px-4 tw-py-1 tw-bg-[#1867C0] tw-text-white
            ${isSending ? 'tw-opacity-50' : ' hover:tw-opacity-[95%] active:tw-opacity-70 '}`

        }
        onClick={!isSending ? handleSubmit(onSubmit) : () => {
          return null
        }}
      >
        <Paragraphs
          className={'tw-tracking-widest tw-scale-x-[0.90]'}
        >
          {lang === 'es' ? 'Enviar' : 'Enviar'}
        </Paragraphs>
      </div>
    </form>
  )
}
ResetPasswordForm.propTypes = {
  userCode: PropTypes.string,
  setUserCode: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  setShowResetPasswordForm: PropTypes.func.isRequired
}
export default ResetPasswordForm
