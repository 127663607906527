import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
import { Error, Visibility, VisibilityOff } from '@mui/icons-material'
import axios from 'axios'
import { useForm } from 'react-hook-form'

const LoginForm = ({ lang, setShowResetPasswordForm, setUserCode, userCode }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm()
  const onSubmit = (data) => {
    const callData = { ...data, origin: 'areaprivada' }
    setLoading(true)
    axios
      .post('https://back.sferafarma.cloud/api/v1/login-app', callData) // OUR PROD BACKEND
      // .post('https://back-sferafarma.enpreproduccion.com/api/v1/login-app', callData) // OUR DEV BACKEND
      .then((response) => {
        if (response?.status === 200 && response?.data?.url) {
          window.location.href = response.data.url
        }
      })
      .catch(() => {
        setLoading(false)
        setError('password', { type: 'string', message: 'custom message' })
      })
  }
  return (
    <form
      name={'login-form'}
      onSubmit={handleSubmit(onSubmit)}
      className={
        'tw-h-48 tw-flex tw-flex-col tw-px-2 tw-pt-12 tw-relative'
      }
    >
      <Paragraphs
        size={'2xl'}
        className={'tw-text-[#666666] tw-self-start tw-ml-0 '}
      >
        {lang === 'es' ? 'Inicio de sesión' : 'Inici de sessió'}
      </Paragraphs>
      <div className={'tw-pt-5'}>
        <label
          className={`tw-text-xs tw-block tw-text-left ${
            errors?.username
              ? 'tw-text-red-500'
              : 'tw-text-[#666666]}'
          }`}
        >
          <Paragraphs size={'xs'} className='tw-text-gray-900/75'>
            {lang === 'es'
              ? 'Código de usuario o dirección de correo'
              : "Codi d'usuari o adreça de mail"}
          </Paragraphs>
        </label>
        <input
          disabled={loading}
          style={{ height: '25px', fontSize: '16px' }}
          className={
            'tw-bg-[#FFF] tw-block tw-text-lg tw-w-full tw-outline-0 tw-border-b tw-border-gray-600 focus:tw-border-[#AE458F] focus:tw-border-b-[2px] tw-transition-all tw-ease-in-out-300 tw-duration-300'
          }
          {...register('username', { required: true, value: userCode })}
          onInput={(e) => { setUserCode(e.target.value) }}
        />
        {errors.username ? (
          <Paragraphs
            size={'xs'}
            className={
              'tw-text-red-500 tw-text-left tw-leading-tight'
            }
          >
            {lang === 'es'
              ? 'Introduce el código de usuario'
              : "Entra el codi d'usuari"}
          </Paragraphs>
        ) : (
          <Paragraphs
            size={'xs'}
            className={'tw-text-white tw-text-left tw-leading-tight'}
          >
            .
          </Paragraphs>
        )}
      </div>

      <div className={'tw-mt-1'}>
        <label
          className={`tw-text-xs tw-block tw-text-left ${
            errors?.password
              ? 'tw-text-red-500'
              : 'tw-text-[#666666]}'
          }`}
        >
          <Paragraphs size={'xs'}>
            {lang === 'es' ? 'Contraseña' : 'Contrasenya'}
          </Paragraphs>
        </label>
        <div className={'tw-flex tw-items-center tw-relative'}>
          <input
            disabled={loading}
            type={showPassword ? 'text' : 'password'}
            style={{ height: '25px', fontSize: '16px' }}
            className={
              'tw-bg-[#FFF] tw-block tw-text-lg tw-w-full tw-outline-0 tw-border-b tw-border-gray-600 focus:tw-border-[#AE458F] focus:tw-border-b-[2px] tw-transition-all tw-ease-in-out-300 tw-duration-300'
            }
            {...register('password')}
          />
          <div
            onClick={() => setShowPassword(!showPassword)}
            className={
              ' tw-z-10 tw-cursor-pointer tw-absolute tw-right-0 tw-bg-white tw-bottom-[0.15rem]'
            }
          >
            <Paragraphs
              className={errors.password && 'tw-text-red-500'}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </Paragraphs>
          </div>
        </div>
        {/* {!errors.password && ( */}
        {/*  <Paragraphs */}
        {/*    size={'xs'} */}
        {/*    className={'tw-text-white tw-scale-x-[0.90]'} */}
        {/*  > */}
        {/*    x{'\n'}x */}
        {/*  </Paragraphs> */}
        {/* )} */}
        {errors.password && (
          <Paragraphs
            size={'xs'}
            className={
              'tw-text-red-500 tw-text-left tw-leading-tight '
            }
          >
            <Error className={'tw-scale-75 '} />{' '}
            {lang === 'es'
              ? 'El nombre de usuario o la contraseña suministrada no son válidos'
              : "El nom d'usuari o la contrasenya subministrada no són vàlids"}
          </Paragraphs>
        )}
      </div>
      <div className={'tw-absolute tw-top-[230px] tw-right-0 tw-hidden'} onClick={() => setShowResetPasswordForm(true)}>
        <Paragraphs className={'tw-cursor-pointer tw-text-[#1867C0]'} size={'sm'}>
          {lang === 'es' ? 'He olvidado mi contraseña' : 'He oblidat la meva contrasenya'}
        </Paragraphs>
      </div>
      <div
        disabled={loading}
        className={
          'tw-absolute tw-top-[270px] tw-right-0 tw-cursor-pointer tw-shadow-gray-black tw-shadow tw-flex tw-justify-between tw-inline-block tw-px-4 tw-py-1 tw-bg-[#1867C0] tw-text-white hover:tw-opacity-[95%] active:tw-opacity-70 tw-rounded-full tw-text-xs'
        }
        onClick={handleSubmit(onSubmit)}
      >
        <Paragraphs
          className={'tw-tracking-widest tw-scale-x-[0.90]'}
        >
          {lang === 'es' ? 'Entrar' : 'Entra'}
        </Paragraphs>
      </div>
    </form>
  )
}

LoginForm.propTypes = {
  lang: PropTypes.string.isRequired,
  setUserCode: PropTypes.func.isRequired,
  setShowResetPasswordForm: PropTypes.func.isRequired,
  userCode: PropTypes.string.isRequired
}

export default LoginForm
